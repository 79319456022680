<template>
  <div class="MarkingTask">
    <detailsHeader
      ref="detailsHeader"
      router-url="/exam/unionExam"
      :query="{
        examId: $route.query.examId,
      }"
    ></detailsHeader>
    <div class="tab-box">
      <div class="tab-title">
        <span>阅卷任务列表</span> 正常卡人数/考生人数：{{
          sheetCount.sheetCount
        }}
        / {{ sheetCount.examStudentCount }}
        <template v-if="indexPaper.scoringMode == 2">
          <span class="title-item">
            正评开关
            <el-switch
              v-model="examSwitchAll"
              active-color="#2474ED"
              :active-value="1"
              :inactive-value="0"
              :disabled="examSwitchVOList.length == 0"
              @change="setChangeAll('examSwitchAll', 'positiveSwitch')"
            >
            </el-switch>
          </span>
          <span class="title-item" style="margin-right: 16px">
            试评开关
            <el-switch
              v-model="examTempSwitchAll"
              active-color="#2474ED"
              :active-value="1"
              :inactive-value="0"
              :disabled="examSwitchVOList.length == 0"
              @change="setChangeAll('examTempSwitchAll', 'tempSwitch')"
            >
            </el-switch>
          </span>
          <div>
            <el-radio v-model="openTypeAll" :label="1" @change="showModelAll()">
              预约任务开启时间
            </el-radio>
            <span v-if="groupPaper.openType == 1">
              任务（
              <template v-if="groupPaper.appointmentType == 0">正评</template>
              <template v-if="groupPaper.appointmentType == 1">试评</template>
              <template v-if="groupPaper.appointmentType == 2"
                >正评和试评</template
              >
              ）开启时间为：
              <span class="time-btn"
                >{{ groupPaper.appointmentOpeningTime }}
                <el-button type="text" @click="editTimeAll(groupPaper)"
                  >修改时间</el-button
                >
              </span>
            </span>
          </div>
        </template>
      </div>
      <groupBox v-if="indexPaper.scoringMode == 2" ref="groupBox"></groupBox>
      <div class="switch-box">
        <el-radio v-model="openType" :label="0" @change="setNow()">
          立即开启阅卷任务
        </el-radio>
        <el-radio v-model="openType" :label="1" @change="showModel()">
          预约任务开启时间
        </el-radio>
        <span v-if="examPaper.openType == 1">
          任务（
          <template v-if="examPaper.appointmentType == 0">正评</template>
          <template v-if="examPaper.appointmentType == 1">试评</template>
          <template v-if="examPaper.appointmentType == 2">正评和试评</template>
          ）开启时间为：
          <span class="time-btn"
            >{{ examPaper.appointmentOpeningTime }}
            <el-button type="text" @click="editTime(examPaper)"
              >修改时间</el-button
            >
          </span>
        </span>
      </div>
      <el-table
        v-loading="listLoading"
        :data="examSwitchVOList"
        border
        style="width: 100%"
      >
        <el-table-column
          prop="examQuestionNumber"
          label="题目名称"
          align="center"
        >
          <template slot-scope="{ row }">
            <template v-if="row.isOption == 1">选做题</template>
            <template v-else>主观题</template>
            {{ row.examQuestionNumber }}
          </template>
        </el-table-column>
        <el-table-column prop="date" label="阅卷方式" align="center">
          <template slot-scope="{ row }">
            <template v-if="row.markType == 3">
              分项给分 -
              {{ row.pointType | setName(scoreTypeItem) }}</template
            >
            <template v-if="row.markType != 3">
              {{ row.ruleScore | setName(scoreRulesList) }}
              <div
                v-if="row.markStep == 1 && row.markScore && row.markType != 3"
              >
                {{ row.markType | setName(scoreMethod) }}
                步长{{ row.markScore }}分
              </div>
            </template>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="已分配/总任务" align="center">
          <template slot-scope="{ row }">
            {{ row.distributionAmount || 0 }}/{{ row.workloadAll || 0 }}
          </template>
        </el-table-column>

        <el-table-column
          prop="name"
          label="正评开关"
          width="288"
          align="center"
        >
          <template #header>
            正评开关
            <el-switch
              v-model="examSwitch"
              active-color="#2474ED"
              :active-value="1"
              :inactive-value="0"
              :disabled="examSwitchVOList.length == 0"
              @change="setChange('examSwitch')"
            >
            </el-switch>
          </template>
          <template slot-scope="{ row }">
            <el-switch
              v-if="getType(row)"
              v-model="row.examPositiveSwitch"
              :active-value="1"
              :inactive-value="0"
              active-color="#2474ED"
              @change="setChangeList('positiveExamMarkIds', row)"
            >
            </el-switch>
            <template v-else>{{ getVal(row) }}</template>
          </template>
        </el-table-column>
        <el-table-column
          prop="name"
          label="试评开关"
          width="288"
          align="center"
        >
          <template #header>
            试评开关
            <el-switch
              v-model="examTempSwitch"
              active-color="#2474ED"
              :active-value="1"
              :inactive-value="0"
              :disabled="examSwitchVOList.length == 0"
              @change="setChange('examTempSwitch')"
            >
            </el-switch>
          </template>
          <template slot-scope="{ row }">
            <el-switch
              v-if="getType(row)"
              v-model="row.examTempSwitch"
              :active-value="1"
              :inactive-value="0"
              active-color="#2474ED"
              @change="setChangeList('tempExamMarkIds', row)"
            >
            </el-switch>
            <template v-else>{{ getVal(row) }}</template>
          </template>
        </el-table-column>
        <el-table-column prop="address" label="试评数量" align="center">
          <template #header>
            试评数量
            <el-button
              size="mini"
              :disabled="examSwitchVOList.length == 0"
              @click="setNums(true, examSwitchVOList)"
            >
              批量设置
            </el-button>
          </template>
          <template slot-scope="{ row }">
            <el-button
              v-if="getType(row)"
              type="text"
              @click="setNums(false, row)"
            >
              试评数量：{{ row.tempEvaluateNum }}份
            </el-button>
            <template v-else>{{ getVal(row) }}</template>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <bookedTime ref="bookedTime"></bookedTime>
    <editNums ref="editNums"></editNums>
  </div>
</template>

<script>
import detailsHeader from "@/components/detailsHeader.vue";
import bookedTime from "./components/bookedTime.vue";
import editNums from "./components/editNums.vue";
import groupBox from "./components/groupBox.vue";
import { getObj } from "@/core/api/exam/exam";
import {
  examswitchPage,
  examswitchUpdate,
  saveimmediatelytask,
  savegroupimmediatelytask,
  examswitchgroupOn,
  examgroupswitchPage,
  groupupdate,
} from "@/core/api/exam/examMarkingTask";
import {
  scoreMethod,
  scoreRulesList,
  scoreTypeItem,
} from "@/core/util/constdata";
import {
  exammarkUnionSheet,
  schoolGroupList,
} from "@/core/api/exam/examCorrectTask";
import { getStore } from "@/core/util/store";

export default {
  name: "MarkingTask",
  components: { detailsHeader, bookedTime, editNums, groupBox },
  data() {
    return {
      examSwitchVOList: [],
      groupList: [],
      scoreMethod: scoreMethod,
      scoreTypeItem: scoreTypeItem,
      scoreRulesList: scoreRulesList,
      nums: 0,
      time: "",
      examSwitch: 0,
      examTempSwitch: 0,
      openType: "1",
      switchValue: true,
      sheetCount: {},
      listLoading: false,
      examPaper: {},
      indexPaper: {},
      groupPaper: {},
      indexGroup: {},
      openTypeAll: "",
      examTempSwitchAll: "",
      examSwitchAll: "",
      examInformation: {
        name: "",
      },
    };
  },
  async created() {
    await this.getExamDetail();
    await this.getList();
    this.exammarkSheet();
  },
  methods: {
    //联考-获取学校分组列表
    async getschoolGroupList() {
      let data = {
        examId: this.$route.query.examId,
        examPaperId: this.$route.query.examPaperId,
      };
      await schoolGroupList(data)
        .then((res) => {
          this.groupList = res.data.data;
          if (this.groupList.length == 0) {
            this.$confirm("请先前往阅卷分工设置分组!", "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
              showCancelButton: false,
              showClose: false,
              closeOnClickModal: false,
              closeOnPressEscape: false,
            })
              .then(() => {
                let data = {
                  // examName: name,
                  paperId: this.$route.query.examPaperId,
                  examId: this.$route.query.examId,
                  examType: this.examInformation.examType,
                };
                this.$router.push({
                  name: "unionExamCorrectTask",
                  query: data,
                });
              })
              .catch(() => {});
          }
          if (this.groupList.length > 0) {
            if (this.indexGroup.id) {
              this.indexGroup = this.groupList.filter(
                (item) => item.id == this.indexGroup.id
              )[0];
            } else {
              this.indexGroup = this.groupList[0];
            }
          }
        })
        .catch(() => {});
    },
    setNums(type, list) {
      this.$refs.editNums.init(type, list);
    },
    getType(item) {
      let type = true;
      if (item.bindSheetDetailIds) {
        type = false;
        if (item.bindSheetDetailIds.indexOf(item.sheetDetailId) == 0) {
          type = true;
        }
      }
      return type;
    },
    getVal(item) {
      let strArr = [];
      this.examSwitchVOList.map((index) => {
        if (item.bindSheetDetailIds.indexOf(index.sheetDetailId) != -1) {
          strArr.push(index.examQuestionNumber);
        }
      });

      return strArr.join(",") + "绑定批阅";
    },
    // 获取考生数量
    exammarkSheet() {
      let data = {
        examPaperId: this.$route.query.examPaperId,
      };
      // if (this.$route.query.examType == 1) {
      // data.schoolId = this.schoolId || "-1";
      data.schoolId = getStore({ name: "school_id" });
      // }

      if (this.indexPaper.scoringMode == 2) {
        data.groupId = this.indexGroup.id;
      }

      exammarkUnionSheet(data)
        .then((res) => {
          this.sheetCount = res.data.data;
        })
        .catch(() => {
          // clearInterval(this.getNums);
        });
    },
    setChange(key, keyVal) {
      // console.log(keyVal);
      // console.log(key);
      let data = {
        examPaperId: this.$route.query.examPaperId,
      };
      if (keyVal) {
        data[keyVal] = this[key];
      } else {
        data[key] = this[key];
      }

      if (
        this.indexPaper.scoringMode == 2 &&
        key == "examSwitch" &&
        this.examSwitch == 1
      ) {
        if (!this.indexGroup.distributionStatus) {
          this.$confirm(
            "存在分组的阅卷任务未分配完毕，是否继续开启阅卷？ ",
            "提示",
            {
              confirmButtonText: "继续任务分配",
              cancelButtonText: "开启阅卷",
              type: "warning",
              distinguishCancelAndClose: true,
            }
          )
            .then(() => {
              let data = {
                // examName: name,
                paperId: this.$route.query.examPaperId,
                examId: this.$route.query.examId,
                examType: this.examInformation.examType,
              };
              this.$router.push({
                name: "unionExamCorrectTask",
                query: data,
              });
            })
            .catch((e) => {
              if (e == "cancel") {
                this.subChange(data);
              } else {
                this.examSwitch =
                  this.examSwitch == 0
                    ? (this.examSwitch = 1)
                    : (this.examSwitch = 0);
              }
            });
          return;
        }
      }

      this.subChange(data);
    },
    setChangeAll(key, keyVal) {
      let data = {
        examPaperId: this.$route.query.examPaperId,
      };
      if (keyVal) {
        data[keyVal] = this[key];
      } else {
        data[key] = this[key];
      }

      this.examswitchgroupOn(data);
    },
    setChangeList(key, row) {
      let data = {
        examPaperId: this.$route.query.examPaperId,
      };
      if (row.bindSheetDetailIds) {
        let ids = [];
        this.examSwitchVOList.map((item) => {
          if (row.bindSheetDetailIds.indexOf(item.sheetDetailId) != -1) {
            ids.push(item.id);
          }
        });
        data[key] = ids.join(",");
      } else {
        data[key] = row.id;
      }
      this.subChange(data);
    },

    subChange(data) {
      if (this.indexPaper.scoringMode == 2) {
        data.groupId = this.indexGroup.id;
        groupupdate(data)
          .then(() => {
            this.$message({
              showClose: true,
              message: "设置成功",
              type: "success",
            });
            this.getList();
            this.dialogVisible = false;
            this.btnLoading = false;
          })
          .catch(() => {
            this.btnLoading = false;
          });
        return;
      }
      examswitchUpdate(data)
        .then(() => {
          this.$message({
            showClose: true,
            message: "设置成功",
            type: "success",
          });
          this.getList();
          this.dialogVisible = false;
          this.btnLoading = false;
        })
        .catch(() => {
          this.btnLoading = false;
        });
    },
    async getList() {
      let data = {
        examPaperId: this.$route.query.examPaperId,
      };

      if (this.indexPaper.scoringMode == 2) {
        await this.getschoolGroupList();
        if (!this.indexGroup.id) return;
        this.examgroupswitchPage();
        return;
      }
      this.listLoading = true;
      examswitchPage(data)
        .then((res) => {
          this.examPaper = res.data.data.examPaper;
          this.examSwitchVOList = res.data.data.examSwitchVOList;
          this.openType = this.examPaper.openType;
          this.examSwitch = this.examPaper.examSwitch;
          this.examTempSwitch = this.examPaper.examTempSwitch;
          this.listLoading = false;
        })
        .catch(() => {
          this.listLoading = false;
        });
    },
    examgroupswitchPage() {
      let data = {
        examPaperId: this.$route.query.examPaperId,
        groupId: this.indexGroup.id,
      };
      this.listLoading = true;
      examgroupswitchPage(data)
        .then((res) => {
          // 分组与阅卷情况  交换下赋值的内容
          this.examPaper = res.data.data.group;
          this.groupPaper = res.data.data.examPaper;
          this.examSwitchVOList = res.data.data.examSwitchVOList;
          this.openType = this.examPaper.openType;
          this.examSwitch = this.examPaper.examSwitch;
          this.examTempSwitch = this.examPaper.examTempSwitch;
          this.openTypeAll = this.groupPaper.openType;
          this.examSwitchAll = this.groupPaper.examSwitch;
          this.examTempSwitchAll = this.groupPaper.examTempSwitch;
          this.listLoading = false;
        })
        .catch(() => {
          this.listLoading = false;
        });
    },
    showModel() {
      this.$refs.bookedTime.init(true);
    },
    showModelAll() {
      this.$refs.bookedTime.init(true, "", true);
    },
    savegroupimmediatelytask(data) {
      savegroupimmediatelytask(data)
        .then(() => {
          this.$message({
            showClose: true,
            message: "设置成功",
            type: "success",
          });
          this.getList();
        })
        .catch(() => {});
    },
    examswitchgroupOn(data) {
      examswitchgroupOn(data)
        .then(() => {
          this.$message({
            showClose: true,
            message: "设置成功",
            type: "success",
          });
          this.getList();
        })
        .catch(() => {});
    },
    // 联考--阅卷开关即时任务开启
    setNow() {
      let data = {
        examPaperId: this.$route.query.examPaperId,
        openType: 0,
      };
      if (this.indexPaper.scoringMode == 2) {
        if (!this.indexGroup.id) return;
        data.groupId = this.indexGroup.id;
        this.savegroupimmediatelytask(data);
        return;
      }
      saveimmediatelytask(data)
        .then(() => {
          this.$message({
            showClose: true,
            message: "设置成功",
            type: "success",
          });
          this.getList();
        })
        .catch(() => {});
    },
    editTime(data) {
      this.$refs.bookedTime.init(false, data);
    },
    editTimeAll(data) {
      this.$refs.bookedTime.init(false, data, true);
    },

    async getExamDetail() {
      // 获取 考试详情
      const res = await getObj(this.$route.query.examId);
      this.examInformation = res.data.data;
      this.paperList = res.data.data.paperList;
      this.indexPaper = this.paperList.filter(
        (item) => item.paperId == this.$route.query.examPaperId
      )[0];
    },
  },
};
</script>
<style scoped lang="scss">
.MarkingTask {
  padding-top: 18px;
  .groupBox {
    margin-top: 18px;
  }
  .title-item {
    margin-left: 16px;
  }
  .time-btn {
    color: #2474ed;
  }
  .switch-box {
    margin: 18px 0;
    .el-radio {
      margin-bottom: 0;
      margin-right: 6px;
    }
    .el-switch {
      margin-right: 18px;
    }
  }
  .tab-box {
    margin-top: 18px;
    padding: 18px;
    background-color: #ffffff;
    .tab-title {
      display: flex;
    }
  }
}
</style>

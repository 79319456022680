import request from "@/core/services/axios";
// import qs from "qs";

// 联考--获取阅卷开关详情
export function examswitchPage(query) {
  return request({
    url: "/exam/exammark/examswitch/page",
    method: "get",
    params: query,
  });
}

// 联考--获取分组阅卷开关详情
export function examgroupswitchPage(query) {
  return request({
    url: "/exam/exammark/examgroupswitch/page",
    method: "get",
    params: query,
  });
}

// 联考--更新阅卷开关
export function examswitchUpdate(query) {
  return request({
    url: "/exam/exammark/examswitch/update",
    method: "put",
    data: query,
  });
}

// 联考--阅卷开关即时任务开启
export function saveimmediatelytask(query) {
  return request({
    url: "/exam/exammark/examswitch/saveimmediatelytask",
    method: "post",
    data: query,
  });
}

// 联考分组阅卷--阅卷开关即时任务开启
export function savegroupimmediatelytask(query) {
  return request({
    url: "/exam/exammark/examswitch/savegroupimmediatelytask",
    method: "post",
    data: query,
  });
}
// 联考分组阅卷--保存延时任务 正评 试评
export function examswitchgroupDelay(query) {
  return request({
    url: "/exam/exammark/examswitchgroup/delay",
    method: "post",
    data: query,
  });
}

// 联考分组阅卷--打开或关闭总开关 正评 试评
export function examswitchgroupOn(query) {
  return request({
    url: "/exam/exammark/examswitchgroup/on",
    method: "get",
    params: query,
  });
}

// 联考分组阅卷--更新阅卷开关
export function groupupdate(query) {
  return request({
    url: "/exam/exammark/examswitch/groupupdate",
    method: "PUT",
    data: query,
  });
}

// 联考--阅卷开关延时任务开启
export function savedelayedtask(query) {
  return request({
    url: "/exam/exammark/examswitch/savedelayedtask",
    method: "post",
    data: query,
  });
}

// 联考分组阅卷--阅卷开关延时任务保存
export function savegroupdelayedtask(query) {
  return request({
    url: "/exam/exammark/examswitch/savegroupdelayedtask",
    method: "post",
    data: query,
  });
}

// 联考--批量更新阅卷开关--试题试评数量
export function updatetempevaluatenum(query) {
  return request({
    url: "/exam/exammark/examswitch/updatetempevaluatenum",
    method: "post",
    data: query,
  });
}

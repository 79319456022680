var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"groupBox"},[_c('i',{staticClass:"el-icon-arrow-left",on:{"click":function($event){return _vm.scrollFn(-1)}}}),_c('div',{ref:"groupItem",staticClass:"edit-scroll-x-style group-box-item"},_vm._l((_vm.$parent.groupList),function(item,index){return _c('div',{key:index,staticClass:"group-item",class:{
        on: _vm.$parent.indexGroup.id == item.id,
        type1: item.distributionStatus == 0,
        type2: item.distributionStatus == 1,
      },on:{"click":function($event){return _vm.clickItem(item)}}},[_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":(item.distributionStatus == 1 ? '分配完成' : '待分配') +
          ' | ' +
          item.name,"placement":"top"}},[_c('span',{staticClass:"tips"},[_vm._v(" "+_vm._s(item.name.substring(0, 5))+" "),(item.name.length > 5)?[_vm._v("...")]:_vm._e()],2)])],1)}),0),_c('i',{staticClass:"el-icon-arrow-right",on:{"click":function($event){return _vm.scrollFn(1)}}})])
}
var staticRenderFns = []

export { render, staticRenderFns }
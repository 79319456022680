<template>
  <div class="groupBox">
    <i class="el-icon-arrow-left" @click="scrollFn(-1)"></i>
    <div ref="groupItem" class="edit-scroll-x-style group-box-item">
      <div
        v-for="(item, index) in $parent.groupList"
        :key="index"
        class="group-item"
        :class="{
          on: $parent.indexGroup.id == item.id,
          type1: item.distributionStatus == 0,
          type2: item.distributionStatus == 1,
        }"
        @click="clickItem(item)"
      >
        <el-tooltip
          class="item"
          effect="dark"
          :content="
            (item.distributionStatus == 1 ? '分配完成' : '待分配') +
            ' | ' +
            item.name
          "
          placement="top"
        >
          <span class="tips">
            {{ item.name.substring(0, 5) }}
            <template v-if="item.name.length > 5">...</template>
          </span>
        </el-tooltip>
      </div>
    </div>

    <i class="el-icon-arrow-right" @click="scrollFn(1)"></i>
  </div>
</template>

<script>
// import { schoolGroupList } from "@/core/api/exam/examCorrectTask";
export default {
  name: "GroupBox",
  components: {},
  data() {
    return { list: [], indexId: 1 };
  },
  created() {},
  methods: {
    clickItem(item) {
      if (this.$parent.listLoading) return;
      this.$parent.indexGroup = item;
      this.$parent.exammarkSheet();
      this.$parent.examgroupswitchPage();
    },

    scrollFn(type) {
      this.$refs.groupItem.scrollLeft =
        this.$refs.groupItem.scrollLeft + 300 * type;
    },
  },
};
</script>
<style scoped lang="scss">
.groupBox {
  display: flex;
  align-items: baseline;
  background: #ffffff;
  border-bottom: 1px solid #dadadf;
  padding-bottom: 10px;
  .group-box-item {
    display: flex;
    align-items: flex-end;
    max-width: calc(100% - 66px);
  }
  i {
    // position: absolute;
    // top: 4px;
    cursor: pointer;
  }
  .el-icon-arrow-left {
    margin-right: 18px;
  }
  .el-icon-arrow-right {
    margin-left: 18px;
  }
  .group-item {
    flex-shrink: 0;
    position: relative;
    padding-left: 16px;
    margin-left: 18px;
    cursor: pointer;
    &:first-child {
      margin-left: 0;
    }
    &::after {
      position: absolute;
      width: 8px;
      height: 8px;
      background: #dee0e7;
      content: "";
      border-radius: 50%;
      top: 6px;
      left: 0;
    }
  }
  .on {
    color: #2474ed;
    //    &::after {
    //   background: #2474ed;
    //  }
    // &::before {
    //   position: absolute;
    //   width: 100%;
    //   height: 1px;
    //   background: #2474ed;
    //   content: "";
    //   border-radius: 50%;
    //   bottom: 0;
    //   left: 0;
    // }
  }
  .type1 {
    &::after {
      background: #dee0e7;
    }
  }
  .type2 {
    &::after {
      background: #0baa8e;
    }
  }
}
</style>

<template>
  <div class="editNums">
    <el-dialog
      :title="type ? '批量设置试评数量' : '设置试评数量'"
      :visible.sync="dialogVisible"
      width="385px"
    >
      <el-form
        ref="ruleForm"
        :model="ruleForm"
        :rules="rules"
        class="demo-ruleForm"
      >
        <el-form-item label="" prop="tempEvaluateNum">
          <div class="form-item">
            <span>设置小题试评数量：</span>
            <el-input v-model="ruleForm.tempEvaluateNum"></el-input>
            份
          </div>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <template v-if="indexPaper.scoringMode == 2">
          <el-button
            type="primary"
            :loading="btnLoading"
            @click="submitForm(1)"
          >
            保存到所有小组
          </el-button>
          <el-button type="primary" :loading="btnLoading" @click="submitForm()">
            仅保存到本组
          </el-button>
        </template>
        <template v-else>
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" :loading="btnLoading" @click="submitForm()">
            确 定
          </el-button>
        </template>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { updatetempevaluatenum } from "@/core/api/exam/examMarkingTask";
export default {
  name: "EditNums",
  components: {},
  data() {
    var checkNums = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("试评数量不能为空"));
      }
      setTimeout(() => {
        let lint = /^[0-9]*[1-9][0-9]*$/;
        if (!lint.test(value)) {
          callback(new Error("请输入大于0数字"));
        } else {
          callback();
        }
      }, 200);
    };
    return {
      dialogVisible: false,
      btnLoading: false,
      type: false,
      indexPaper: {},
      ruleForm: {
        tempEvaluateNum: "",
      },
      rules: {
        tempEvaluateNum: [{ validator: checkNums, trigger: "blur" }],
      },
    };
  },
  created() {},
  methods: {
    submitForm(subType) {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.setupdatetempevaluatenum(subType);
        } else {
          return false;
        }
      });
    },
    setupdatetempevaluatenum(subType) {
      let data = Object.assign({}, this.ruleForm);

      if (this.type) {
        data.ids = this.subData.map((item) => item.id).join(",");
        if (subType) {
          data.ifAllGroup = true;
        }
      } else {
        data.ids = this.subData.id;
        if (subType) {
          data.ifSingleAllGroup = true;
        }
        if (this.subData.bindSheetDetailIds) {
          let arr = [];
          this.$parent.examSwitchVOList.map((item) => {
            if (
              this.subData.bindSheetDetailIds.indexOf(item.sheetDetailId) != -1
            ) {
              arr.push(item.id);
            }
          });
          data.ids = arr.join(",");
        }
      }
      //   console.log(data);
      this.btnLoading = true;
      if (this.$parent.indexPaper.scoringMode == 2) {
        data.groupId = this.$parent.indexGroup.id;
      }
      updatetempevaluatenum(data)
        .then(() => {
          this.$message({
            showClose: true,
            message: "设置成功",
            type: "success",
          });
          this.$parent.getList();
          this.dialogVisible = false;
          this.btnLoading = false;
        })
        .catch(() => {
          this.btnLoading = false;
        });
    },
    init(type, list) {
      this.type = type;
      this.subData = list;
      this.dialogVisible = true;
      this.indexPaper = this.$parent.indexPaper;
      if (this.type) {
        this.ruleForm.tempEvaluateNum = this.subData[0].tempEvaluateNum || 0;
      } else {
        this.ruleForm.tempEvaluateNum = this.subData.tempEvaluateNum || 0;
      }
    },
  },
};
</script>
<style scoped lang="scss">
.editNums {
  .form-item {
    display: flex;
    .el-input {
      margin-right: 8px;
    }
    > span {
      flex-shrink: 0;
    }
  }
}
</style>

<template>
  <div class="bookedTime">
    <el-dialog
      title="选择任务开启时间"
      :visible.sync="dialogVisible"
      width="470px"
      :before-close="handleClose"
    >
      <div class="tips">
        <img src="@/assets/uExam/icon_waring.png" alt="" />
        注意: <br />
        1、开启正评任务后，则只开启正评任务; <br />
        2、开启试评任务后，则只开启试评任务; <br />
        3、若同时勾选，阅卷流程为先试评后正评;
      </div>
      <div class="from-box">
        <el-form
          ref="ruleForm"
          :model="ruleForm"
          :rules="rules"
          class="demo-ruleForm"
        >
          <el-form-item label="" prop="appointmentTime">
            <span>选择开启时间：</span>
            <el-date-picker
              v-model="ruleForm.appointmentTime"
              type="datetime"
              placeholder="选择日期时间"
              value-format="yyyy-MM-dd HH:mm:ss"
              format="yyyy-MM-dd HH:mm:ss"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="" prop="appointmentType">
            <el-checkbox-group v-model="ruleForm.appointmentType">
              <el-checkbox :label="0">开启正评任务</el-checkbox>
              <el-checkbox :label="1">开启试评任务</el-checkbox>
            </el-checkbox-group>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose()">取 消</el-button>
        <el-button type="primary" :loading="btnLoading" @click="submitForm()">
          确 定
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  savedelayedtask,
  savegroupdelayedtask,
  examswitchgroupDelay,
} from "@/core/api/exam/examMarkingTask";
export default {
  name: "BookedTime",
  components: {},
  data() {
    var valiDate = (rule, value, callback) => {
      if (value.length > 0) {
        var endD = new Date().getTime();
        var timel = new Date(value).getTime();
      }
      if (value == "") {
        callback(new Error("请选择日期"));
      } else if (endD > timel) {
        callback(new Error("只能选择晚于当前的时间"));
      } else {
        callback();
      }
    };

    return {
      dialogVisible: false,
      btnLoading: false,
      ruleForm: {
        appointmentTime: null,
        appointmentType: [],
      },
      rules: {
        appointmentTime: [
          { required: true, validator: valiDate, trigger: "blur" },
        ],
        appointmentType: [
          {
            type: "array",
            required: true,
            message: "至少选择一个任务",
            trigger: "change",
          },
        ],
      },
    };
  },
  created() {},
  methods: {
    init(type, data, editpaperType) {
      this.editpaperType = editpaperType || false;
      this.dialogVisible = true;
      this.ruleForm.appointmentTime = "";
      this.ruleForm.appointmentType = [0, 1];
      this.editType = type;
      if (!type) {
        this.ruleForm.appointmentTime = data.appointmentOpeningTime;
        if (data.appointmentType == 2) {
          this.ruleForm.appointmentType = [0, 1];
        } else {
          this.ruleForm.appointmentType = [data.appointmentType];
        }
      }
    },

    handleClose() {
      if (this.editType) {
        this.$parent.openType = 0;
      }

      this.dialogVisible = false;
    },
    submitTime() {
      //   console.log(this.ruleForm);
      let data = Object.assign({}, this.ruleForm);
      if (data.appointmentType.length == 1) {
        data.appointmentType = data.appointmentType.join("");
      } else {
        data.appointmentType = 2;
      }
      data.openType = 1;
      data.examPaperId = this.$route.query.examPaperId;
      this.btnLoading = true;
      // 修改所有分组的时候
      if (this.editpaperType) {
        examswitchgroupDelay(data)
          .then(() => {
            this.$message({
              showClose: true,
              message: "设置成功",
              type: "success",
            });
            this.$parent.getList();
            this.dialogVisible = false;
            this.btnLoading = false;
          })
          .catch(() => {
            this.btnLoading = false;
          });
        return;
      }
      // 修改单个分组的时候
      if (this.$parent.indexPaper.scoringMode == 2) {
        data.groupId = this.$parent.indexGroup.id;
        savegroupdelayedtask(data)
          .then(() => {
            this.$message({
              showClose: true,
              message: "设置成功",
              type: "success",
            });
            this.$parent.getList();
            this.dialogVisible = false;
            this.btnLoading = false;
          })
          .catch(() => {
            this.btnLoading = false;
          });
        return;
      }
      // 修改普通开关的时候
      savedelayedtask(data)
        .then(() => {
          this.$message({
            showClose: true,
            message: "设置成功",
            type: "success",
          });
          this.$parent.getList();
          this.dialogVisible = false;
          this.btnLoading = false;
        })
        .catch(() => {
          this.btnLoading = false;
        });
    },

    submitForm() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.submitTime();
        } else {
          return false;
        }
      });
    },
  },
};
</script>
<style scoped lang="scss">
.bookedTime {
  .tips {
    background: #fff3f3;
    border-radius: 4px;
    padding: 12px 40px;
    position: relative;
    img {
      position: absolute;
      left: 18px;
      top: 12px;
    }
  }
  .from-box {
    margin-top: 12px;
    .el-checkbox {
      margin-bottom: 0;
    }
    .el-form-item {
      margin-bottom: 10px;
    }
  }
}
</style>
